"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class ScriptLoader {
  constructor(src, flag = null) {
    __publicField(this, "isLoaded", false);
    __publicField(this, "isLoading", false);
    __publicField(this, "callbacks", []);
    __publicField(this, "src");
    __publicField(this, "flag");
    __publicField(this, "load", () => {
      if (!this.isLoading && !this.isLoaded) {
        this.isLoading = true;
        const script = document.createElement("script");
        script.async = true;
        script.defer = true;
        script.src = this.src;
        script.onload = () => {
          if (this.flag === null) {
            setTimeout(this.setLoaded, 150);
          } else {
            const timer = window.setInterval(() => {
              if (this.flag) {
                window.clearInterval(timer);
                this.setLoaded();
              }
            }, 10);
          }
        };
        document.body.appendChild(script);
      }
    });
    __publicField(this, "setLoaded", () => {
      this.isLoaded = true;
      this.isLoading = false;
      this.callbacks.forEach((cb) => cb());
    });
    __publicField(this, "onReady", (cb) => {
      if (!this.isLoading && !this.isLoaded)
        this.load();
      if (this.isLoaded) {
        cb();
      } else {
        this.callbacks.push(cb);
      }
    });
    this.src = src;
    this.flag = flag;
  }
}
